@import url('https://fonts.googleapis.com/css2?family=Onest:wght@400;500;700&display=swap');

body {
  background-color: white;
  margin: 0;
  padding: 0;
  font-family: 'Onest', sans-serif;
  text-transform: none;
  color-scheme: light;
}

button, a, p, h1, h2, h3, h4, h5, h6, li {
  font-family: 'Onest', sans-serif;
  text-transform: none;
}
